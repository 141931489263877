<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
        <div class="page-desc">
           管理员将A地的空闲资产调拨到B地，让B地的人员正常领用、管理
         </div>   
		<TableVue ref="c_table"></TableVue>
	</div>   
</template>
<script>
import TableVue from './Common/Table.vue'
export default {
    components:{
		TableVue
    },
	 inject:['reload'],
   data() {
      return {
      }
   },
   created(){
		this.$nextTick(()=>{
			this.$refs.c_table.rUrl = {
				index:'/AssetAllot/index',
				add:'/AssetAllot/add',
				edit:'/AssetAllot/edit',
				del:'/AssetAllot/del',
				export:'/AssetAllot/export'
			} 
			this.$refs.c_table.cardName =  '资产调拨单'
			this.$refs.c_table.storageType =  7
			this.$refs.c_table.expandStatus = true
			this.$refs.c_table.getData();
		})
   },
   mounted(){
		
   },
   methods:{
		
   }

}
</script>

</style>